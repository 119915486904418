
import Vue from 'vue'
import { IHeaderOption, KwikMediumContainer, KwikPageToolbar } from 'kwik-vue'
import { dispatchGetWarehouseMaterials } from '../store/actions'
import { readWarehouseMaterials } from '../store/getters'

export default Vue.extend({
  name: 'FFWarehouse',
  components: { KwikMediumContainer, KwikPageToolbar },
  watch: {
    options: {
      async handler () {
        await this.loadPage()
      },
      deep: true
    },
    filters: {
      async handler () {
        await this.loadPage()
      },
      deep: true
    }
  },
  data () {
    return {
      options: {} as any,
      loading: true,
      breadcrumbs: [
        { text: 'Food Factory', disabled: true },
        { text: 'Magazzini', disabled: false }
      ]
    }
  },
  computed: {
    headers () : IHeaderOption[] {
      const defaultHeaders = [
        {
          text: 'Materiale',
          sortable: true,
          value: 'material_name',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Quantità',
          sortable: true,
          value: 'quantity',
          hidden: false,
          align: 'start'
        },
        {
          text: 'UM',
          sortable: true,
          value: 'material_unit_of_measure',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Ordinato/Pianificato',
          sortable: true,
          value: 'planned',
          hidden: false,
          align: 'start'
        }
        // {
        //   text: this.$t('commons.buttons.actions'),
        //   value: 'actions',
        //   sortable: false,
        //   hidden: false
        // }
      ] as IHeaderOption[]
      return defaultHeaders
    },
    warehouseItems () {
      return readWarehouseMaterials(this.$store)
    }
  },
  methods: {
    async loadPage () {
      this.loading = true
      // const paging: IPagingInput = pagingBuilder(this.options)
      // const sorting: ISortingInput = sortingBuilder(this.options)
      // const payload: IWarehouseGetInput = {
      //   paging: paging,
      //   sorting: sorting
      // }
      await dispatchGetWarehouseMaterials(this.$store)
      this.loading = false
    }
  },
  async mounted () {
    await this.loadPage()
  }
})
