
import Vue from 'vue'
import { pagingBuilder, sortingBuilder } from 'kwik-vue/utils/tables'
import { IHeaderOption, IPagingInput, ISortingInput, KwikMediumContainer, KwikPageToolbar } from 'kwik-vue'
import { IBankingGetInput } from '../types'
import { dispatchGetBankingCurrentPage } from '../store/actions'
import { readBankingCount, readBankingCurrentPage } from '../store/getters'
import { clockToFormatDate } from '../../../utils/clockManager'

export default Vue.extend({
  name: 'FFBanking',
  components: { KwikMediumContainer, KwikPageToolbar },
  data () {
    return {
      options: {} as any,
      loading: true,
      breadcrumbs: [
        { text: 'Food Factory', disabled: true },
        { text: 'Conto corrente', disabled: false }
      ]
    }
  },
  computed: {
    headers () : IHeaderOption[] {
      const defaultHeaders = [
        {
          text: 'ID',
          sortable: true,
          value: 'id',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Importo',
          sortable: true,
          value: 'amount',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Data e ora',
          sortable: true,
          value: 'transaction_clock',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Causale',
          sortable: true,
          value: 'reason',
          hidden: false,
          align: 'start'
        }
      ] as IHeaderOption[]
      return defaultHeaders
    },
    bankingItems () {
      return readBankingCurrentPage(this.$store)
    },
    total () {
      return readBankingCount(this.$store)
    }
  },
  methods: {
    async loadPage () {
      this.loading = true
      const paging: IPagingInput = pagingBuilder(this.options)
      const sorting: ISortingInput = sortingBuilder(this.options)
      const payload: IBankingGetInput = {
        paging: paging,
        sorting: sorting
      }
      await dispatchGetBankingCurrentPage(this.$store, payload)
      this.loading = false
    },
    clockToFormatDate (clock: number) {
      return clockToFormatDate(clock, 'shortDateTime')
    }
  },
  watch: {
    options: {
      async handler () {
        await this.loadPage()
      },
      deep: true
    },
    filters: {
      async handler () {
        await this.loadPage()
      },
      deep: true
    }
  }
})
