import { render, staticRenderFns } from "./FFBanking.vue?vue&type=template&id=674e46fe&scoped=true"
import script from "./FFBanking.vue?vue&type=script&lang=ts"
export * from "./FFBanking.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "674e46fe",
  null
  
)

export default component.exports